import React from "react";
import "./styles.css";

const ctaWpp = (props) => {
  return (
    <figure id="ctaWpp">
      <a
        href="http://wa.me/+5511990200707?text=Vi%20no%20Tiktok%20e%20gostaria%20de%20informações%20sobre%20o%20pacote%20Box250"
        target="_blank"
        rel="noreferrer"
      >
        <img src={props.cta} alt="Chamada de ação para Whatsapp" />
      </a>
    </figure>
  );
};

export default ctaWpp;
