import "./styles.css";

import CTAForm from "../../components/CTAForm";
import CTAWpp from "../../components/CTAWpp";

import about1 from "../../assets/about1.png";
import bullet from "../../assets/bullet.png";
import about2 from "../../assets/about2.png";
import icon1 from "../../assets/icon1.png";
import icon2 from "../../assets/icon2.png";
import icon3 from "../../assets/icon3.png";
import icon4 from "../../assets/icon4.png";
import icon5 from "../../assets/icon5.png";
import icon6 from "../../assets/icon6.png";
import about3 from "../../assets/about3.png";
import about3Mobile from "../../assets/mobile/about3.png";
import yes from "../../assets/yes.png";
import no from "../../assets/no.png";
import about4 from "../../assets/about4.png";
import cta from "../../assets/call_whatsapp2.png";
import ctaMobile from "../../assets/mobile/call_whatsapp2.png";
import cta2 from "../../assets/call_form.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <div className="left">
            <h2 className="title">
              Por que ter uma Agência de Marketing Digital cuidando da sua
              campanha?
            </h2>

            <p className="text">
              Nós cuidamos da sua campanha de forma profissional e exclusiva,
              utilizando as melhores estratégias e tecnologias para que você
              possa obter:
            </p>

            <ul>
              <li>
                <img src={bullet} alt="Bullet point" />
                <p>Até 40% menos gastos desqualificados;</p>
              </li>
              <li>
                <img src={bullet} alt="Bullet point" />
                <p>Mais resultados;</p>
              </li>
              <li>
                <img src={bullet} alt="Bullet point" />
                <p>
                  Realizamos uma análise completa dos dados da sua campanha;.
                </p>
              </li>
              <li>
                <img src={bullet} alt="Bullet point" />
                <p>Oferecemos acompanhamento e ajustes;</p>
              </li>
              <li>
                <img src={bullet} alt="Bullet point" />
                <p>
                  E o principal, você mais focado no que realmente importa:{" "}
                </p>
              </li>
              <li>
                <p>o sucesso do seu negócio.</p>
              </li>
            </ul>

            <CTAForm cta={cta2} />
          </div>

          <div className="right">
            <p className="text">
              <span>Utilização de Inteligência Artificial</span> na configuração
              da campanha para maior performance!
            </p>

            <figure>
              <img src={about1} alt="Imagem de um robô" />
            </figure>

            <CTAForm cta={cta2} />
          </div>
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <figure>
            <img src={about2} alt="Box 250" />
          </figure>

          <h2 className="title">
            <span>Ao escolher o Box250 da M Agência Digital, você</span> terá um
            pacote completo e exclusivo, que inclui:
          </h2>

          <ul className="gallery">
            <li>
              <img src={icon1} alt="Ícone 1" />
              <p>Gerenciamento Completo da sua campanha</p>
            </li>
            <li>
              <img src={icon2} alt="Ícone 2" />
              <p>
                Planejamento Estratégico, Configuração, Acompanhamento e
                Otimização
              </p>
            </li>
            <li>
              <img src={icon3} alt="Ícone 3" />
              <p>Segmentação de Público-alvo e Seleção de Palavras-chave</p>
            </li>
            <li>
              <img src={icon4} alt="Ícone 4" />
              <p>Criação de Textos e Imagens para os Anúncios.</p>
            </li>
            <li>
              <img src={icon5} alt="Ícone 5" />
              <p>Implantação de Código em seu Site para Monitoramento</p>
            </li>
            <li>
              <img src={icon6} alt="Ícone 6" />
              <p>Suporte via WhatsApp de segunda à sexta das10h às 19h.</p>
            </li>
          </ul>

          <CTAWpp
            cta={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? ctaMobile
                : cta
            }
          />
        </div>
      </article>

      <article className="about3">
        <div className="container">
          <h2 className="title">Tenho garantia de resultados?</h2>

          <div className="info">
            <div className="left">
              <figure>
                <img src={yes} alt="Sim" />
              </figure>
              <p>
                Somos uma agência de performance, e isso quer dizer que{" "}
                <b>
                  vamos trabalhar para que você tire o máximo de proveito do seu
                  investimento na sua campanha
                </b>
                , ou seja, gradativamente você irá ver um melhor desempenho da
                sua campanha
              </p>
            </div>

            <figure className="middle">
              <img
                src={
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                  )
                    ? about3Mobile
                    : about3
                }
                alt="Garantia de Resultados"
              />
            </figure>

            <div className="right">
              <figure>
                <img src={no} alt="Não" />
              </figure>
              <p>
                Como já falamos, somos uma agência transparente,{" "}
                <b>sem falsas promessas</b>, e por isso não podemos prever
                quantos contatos ou quantas vendas você vai ter, estes fatores
                não dependem apenas da campanha, dependem do potencial do seu
                site e principalmente do seu potencial de vendas.
              </p>
            </div>
          </div>
        </div>
      </article>

      <article className="about4">
        <div className="container">
          <div className="left">
            <h2 className="title">é seguro?</h2>

            <p className="text">
              A M Agência Digital está a mais de 15 anos no mercado. Localizada
              na cidade de Mogi das Cruzes/SP, atendendo a mais de 100 clientes
              em todo o Brasil e Portugal com campanhas de alta performance que
              trazem clientes potenciais para seu negócio.
            </p>

            <figure className="alvo">
              <img src={about4} alt="Imagem de um alvo" />
            </figure>

            <p className="text2">
              Trabalhamos com transparência, sem falsas promessas e dando
              suporte aos nossos clientes:
            </p>

            <ul>
              <li>
                <img src={bullet} alt="Bullet point" />
                <p>
                  Campanha do cliente - tudo que montarmos para sua campanha
                  será seu, mesmo encerrando o serviço conosco;
                </p>
              </li>
              <li>
                <img src={bullet} alt="Bullet point" />
                <p>
                  Cliente com acesso as contas - montamos a campanha na conta do
                  cliente, caso não tenha, criamos uma conta e damos acesso a
                  ela;
                </p>
              </li>
              <li>
                <img src={bullet} alt="Bullet point" />
                <p>
                  Pagamento das mídias diretamente para as mídias - Todo
                  investimento dedicado a campanha, vai para a campanha, você
                  tem controle total do investimento;
                </p>
              </li>
              <li>
                <img src={bullet} alt="Bullet point" />
                <p>
                  Este pacote não tem contrato de fidelidade, você pode cancelar
                  quando desejar.
                </p>
              </li>
            </ul>

            <CTAForm cta={cta2} />
          </div>

          <figure className="right">
            <img src={about4} alt="Imagem de um alvo" />
          </figure>
        </div>
      </article>
    </section>
  );
};

export default About;
