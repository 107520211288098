import "./styles.css";

import CTAWpp from "../../components/CTAWpp";
import CTAForm from "../../components/CTAForm";

import content1 from "../../assets/content1.png";
import content1Mobile from "../../assets/mobile/content1.png";
import cta from "../../assets/call_whatsapp3.png";
import ctaMobile from "../../assets/mobile/call_whatsapp3.png";
import lp from "../../assets/lp.png";
import lpMobile from "../../assets/mobile/lp.png";
import sm from "../../assets/sm.png";
import smMobile from "../../assets/mobile/sm.png";
import cta2 from "../../assets/call_form2.png";

const Content = () => {
  return (
    <section id="content">
      <article className="content1">
        <div className="container">
          <h2 className="title">Nossos Números</h2>

          <figure>
            <img
              src={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? content1Mobile
                  : content1
              }
              alt="Cases"
            />
          </figure>

          <CTAWpp
            cta={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? ctaMobile
                : cta
            }
          />
        </div>
      </article>

      <article className="content2">
        <div className="container">
          <h2 className="title">Serviços Adicionas que também oferecemos</h2>

          <div className="gallery">
            <div className="item">
              <figure>
                <img
                  src={
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                      navigator.userAgent
                    )
                      ? lpMobile
                      : lp
                  }
                  alt="Landing Page"
                />
              </figure>

              <CTAForm cta={cta2} />
            </div>

            <div className="item">
              <figure>
                <img
                  src={
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                      navigator.userAgent
                    )
                      ? smMobile
                      : sm
                  }
                  alt="Pacote Social Media"
                />
              </figure>

              <CTAForm cta={cta2} />
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Content;
