import "./styles.css";

import Form from "../../components/Form";

const FormPage = () => {
  return (
    <section id="FormPage">
      <article className="container">
        <h2 className="title">Solicite sua Proposta</h2>
        <p className="subtitle">
          Preencha o formulário, entraremos em contato:
        </p>

        <Form />
      </article>
    </section>
  );
};

export default FormPage;
