import React from "react";
import "./styles.css";

const ctaForm = (props) => {
  return (
    <figure id="ctaForm">
      <a href="#form">
        <img src={props.cta} alt="Chamada de ação para Formulário" />
      </a>
    </figure>
  );
};

export default ctaForm;
