import React from "react";
import "./styles.css";

import logo from "../../assets/logo_rodape.png";
import footer1 from "../../assets/footer1.png";
import footer1Mobile from "../../assets/mobile/footer1.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer1">
        <div className="container">
          <figure className="left">
            <img src={logo} alt="Logo" />
          </figure>

          <div className="right">
            <h2 className="right_title">Formas de pagamento:</h2>

            <figure>
              <img
                src={
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                  )
                    ? footer1Mobile
                    : footer1
                }
                alt="Formas de pagamento"
              />
            </figure>
          </div>
        </div>
      </div>

      <div className="footer2">
        <div className="container">
          <p className="text">
            2023 © Copyrights M Agência Digital CNPJ - 20.188.976/0001-47
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
