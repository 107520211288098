import "./styles.css";

import CTAForm from "../../components/CTAForm";
import CTAWpp from "../../components/CTAWpp";

import header2 from "../../assets/header2.png";
import icons from "../../assets/icons.png";
import cta from "../../assets/call_whatsapp.png";
import ctaMobile from "../../assets/mobile/call_whatsapp.png";
import cta2 from "../../assets/call_form.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <CTAForm cta={cta2} />
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <div className="top">
            <div className="left">
              <h1 className="title">PACOTE EXCLUSIVO</h1>
              <p className="subtitle">
                IDEAL PARA PEQUENAS EMPRESAS OU PROFISSIONAIS
                AUTÔNOMOS/EMPREENDEDORES
              </p>

              <figure>
                <img src={icons} alt="Ícones de redes sociais" />
              </figure>

              <div className="left_text">
                <p className="textlight">Apenas</p>
                <p className="text">R$ 250,00/mês</p>
                <p className="textlight">sem contrato</p>
              </div>

              <p className="little">
                Para investimento até R$ 1.000,00/mês em campanha (valor pago ao
                Google, Facebook, Instagram, WhatsApp ou Ticktok).
              </p>

              <p className="price">Pague com cartão, boleto ou pix</p>
            </div>

            <div className="right">
              <figure>
                <img
                  src={header2}
                  alt="Imagens sobre carreira, up nas vendas, etc."
                />
              </figure>

              <div className="mobile">
                <div className="left_text">
                  <p className="textlight">Apenas</p>
                  <p className="text">R$ 250,00/mês</p>
                  <p className="textlight">sem contrato</p>
                </div>

                <p className="little">
                  Para investimento até R$ 1.000,00/mês em campanha (valor pago
                  ao Google, Facebook, Instagram, WhatsApp ou Ticktok).
                </p>

                <p className="price">Pague com cartão, boleto ou pix</p>
              </div>
            </div>
          </div>

          <div className="bottom">
            <CTAWpp
              cta={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? ctaMobile
                  : cta
              }
            />
          </div>
        </article>
      </section>
    </header>
  );
};

export default Header;
